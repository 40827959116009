import React, { useEffect } from "react"
import { graphql, navigate, PageProps } from "gatsby"
import { ILocale, IndexRedirectQuery } from "../../../gatsby-graphql"
import { useTranslation } from "react-i18next"

interface IndexRedirectProps extends PageProps {
  data: IndexRedirectQuery
}

const getRedirectLanguage = (enabledLanguages: ILocale[], lang: string) => {
  if (!lang) {
    return "en"
  }
  const languages = enabledLanguages.map(l => l.code as string)
  return languages.includes(lang) ? lang : "en"
}

const IndexRedirect = ({ data }: IndexRedirectProps) => {
  const {i18n} = useTranslation();
  useEffect(() => {
    const lang = getRedirectLanguage(data.global?.enabledLanguages as ILocale[], i18n.language);
    navigate(`/${lang}/`, { replace: true })
  }, [])
  return null
}

export default IndexRedirect

export const pageQuery = graphql`
  query IndexRedirect {
    global: strapiGlobal {
      ...FGlobal
    }
  }
`
